// @ts-strict-ignore
import type { SerializedStyles, Theme } from "@emotion/react";
import { faCheck, faMinus } from "@fortawesome/pro-regular-svg-icons";
import type { HTMLProps, ReactNode } from "react";
import { useEffect, useRef } from "react";

import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import { DeprecatedCheckbox } from "./DeprecatedCheckbox";

import * as styles from "./Checkbox.styles";

interface Props extends HTMLProps<HTMLInputElement> {
  /**
   * Adds custom styles to the label text styling
   */
  labelStyle?: SerializedStyles[] | SerializedStyles | ((theme: Theme) => SerializedStyles);
  /**
   * Sets the checkbox wrapper to be fullwidth
   *
   * @default false
   */
  isFullWidth?: boolean;
  /**
   * When true, sets the checkbox content to be fullwidth
   *
   * @default false
   */
  isContentFullWidth?: boolean;
  /**
   * Sets the checkbox to be indeterminate (an uncertain state where some options are checked, but not all)
   *
   * @default false
   */
  indeterminate?: boolean;
  /**
   * Content to render alongside the checkbox
   *
   * @default undefined
   */
  children?: ReactNode;
  /**
   * Callback for when the checkbox changes state
   *
   */
  onCheckedChange?: (checked: boolean) => void;
  /**
   * Checkbox vertical position
   *
   * @default "center"
   */
  checkboxPosition?: "center" | "top";
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/inputs/Checkbox
 *
 * @deprecated Use `Checkbox` from `@fullscript/aviary-web` instead.
 */
const Checkbox = (props: Props) => {
  const {
    children,
    disabled,
    id,
    isFullWidth = false,
    isContentFullWidth = false,
    labelStyle,
    onCheckedChange,
    indeterminate = false,
    checkboxPosition = "center",
    ...rest
  } = props;

  const isDS4Enabled = useIsDS4Enabled();
  const checkboxRef = useRef<HTMLInputElement>(undefined);
  const isCheckboxPositionTop = checkboxPosition === "top";

  useEffect(() => {
    if (checkboxRef.current) checkboxRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  if (!isDS4Enabled) return <DeprecatedCheckbox {...{ ...props, isFullWidth, indeterminate }} />;

  const wrapperStyles = () => [
    styles.checkbox.base,
    isFullWidth && styles.checkbox.isFullWidth,
    disabled && styles.checkbox.disabled,
    isCheckboxPositionTop && styles.checkbox.top,
    labelStyle,
  ];

  const iconContainerStyles = () => [
    styles.iconContainer.base,
    disabled && styles.iconContainer.disabled,
    isCheckboxPositionTop && styles.iconContainer.top,
  ];

  const checkboxContentStyles = () => [
    styles.checkboxContent.base,
    isContentFullWidth && styles.checkboxContent.isFullWidth,
    disabled && styles.checkboxContent.disabled,
  ];

  const iconStyles = () => [styles.icon.base, disabled && styles.icon.disabled];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onCheckedChange) {
      onCheckedChange(e.target.checked);
    }
  };

  return (
    <label htmlFor={id} css={wrapperStyles()} aria-disabled={disabled} data-testid="checkbox-label">
      <input
        id={id}
        css={styles.checkboxInput}
        type="checkbox"
        disabled={disabled}
        ref={checkboxRef}
        aria-checked={indeterminate ? "mixed" : rest.checked}
        onChange={handleInputChange}
        {...rest}
      />
      <div css={iconContainerStyles()}>
        <FontAwesomeIcon css={iconStyles()} icon={indeterminate ? faMinus : faCheck} />
      </div>
      {!!children && <div css={checkboxContentStyles()}>{children}</div>}
    </label>
  );
};

export { Checkbox };
